<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <span class="text_1">科技赋能&nbsp;构筑绿色卫生城市</span>
      <span class="text_2">智慧环卫管理平台</span>
      <span class="paragraph_1"
        >智慧环卫综合管理指挥平台运用云计算、大数据、物联网、互联网、安防视频和工业控制等相关技术，建设统一的管理信息共享平台，实现对环卫管理所涉及的人、车、物、事件进行全过程实时监督，提升环卫作业质量，降低环卫运营成本。</span
      >
      <div class="box_2 flex-col">
        <div class="text-wrapper_1 flex-col" @click="freeClick">
          立即咨询体验
        </div>
      </div>
    </div>
    <div class="box_3 flex-col">
      <div class="text-group_1 flex-col justify-between">
        <span class="text_4">应用场景</span>
        <span class="paragraph_2"
          >深入应用前沿技术，实现环卫道路保洁、垃圾收运、垃圾处置、垃圾分类、公厕管理等多业务的智能监管、高效率质量督查、精细化层级考核、可视化数据分析研判，形成对环卫作业全流程的精细化、智能化、可视化及自动化管理。</span
        >
      </div>
      <div class="block_1 flex-row justify-between">
        <div class="flex-col align-center">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/2.png"
          />
          <div class="text_5">机械作业</div>
        </div>
        <div class="align-center flex-col">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/3.png"
          />
          <div class="text_5">人员作业</div>
        </div>
        <div class="align-center flex-col">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/4.png"
          />
          <div class="text_5">垃圾运输</div>
        </div>
        <div class="align-center flex-col">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/5.png"
          />
          <div class="text_5">作业监督</div>
        </div>
      </div>
      <div class="block_1 flex-row justify-between">
        <div class="flex-col align-center">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/6.png"
          />
          <div class="text_5">事件监督</div>
        </div>
        <div class="align-center flex-col">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/7.png"
          />
          <div class="text_5">公厕监督</div>
        </div>
        <div class="align-center flex-col">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/8.png"
          />
          <div class="text_5">设施管理</div>
        </div>
        <div class="align-center flex-col">
          <img
            class="image-wrapper_1"
            referrerpolicy="no-referrer"
            src="../assets/9.png"
          />
          <div class="text_5">环卫作业考核</div>
        </div>
      </div>
      <span class="text_13">场景展示</span>
      <div class="list_1 flex-row">
        <div
          class="image-text_1 flex-col justify-between"
          v-for="(item, index) in list"
          :key="index"
          @click="listClick(index)"
        >
          <img
            :class="listIndex == index ? 'image_9_1' : 'image_9'"
            referrerpolicy="no-referrer"
            :src="item.image"
          />
          <span
            :class="listIndex == index ? 'text-group_2_1' : 'text-group_2'"
            v-html="item.name"
          ></span>
        </div>
      </div>
      <div v-if="list[listIndex].bigImage">
        <img
          class="image_10"
          referrerpolicy="no-referrer"
          :src="list[listIndex].bigImage"
        />
      </div>

      <div id="group_1">
        <div
          class="text_14"
          :class="OneShow ? 'animate__animated animate__zoomIn' : ''"
        >
          作业质量考核
        </div>
        <div
          class="text_15"
          :class="OneShow ? 'animate__animated animate__zoomIn' : ''"
        >
          采用定期检查与不定期检查相结合，每日检查与日常抽查相结合，全面巡查与专项抽查相结合的方式进行管理；支持手机APP监管调度流程处理，可随时随地办公，提高监管效率。
        </div>
        <div class="block_3 flex-row">
          <div
            class="flex-row"
            :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
          >
            <div class="group_1 flex-col">
              <div class="image-wrapper_9 flex-col">
                <img
                  class="label_1"
                  referrerpolicy="no-referrer"
                  src="../assets/13.png"
                />
              </div>
              <div class="image-wrapper_10 flex-col">
                <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="../assets/14.png"
                />
              </div>
              <div class="image-wrapper_11 flex-col">
                <img
                  class="label_3"
                  referrerpolicy="no-referrer"
                  src="../assets/15.png"
                />
              </div>
            </div>
            <div class="group_2 flex-col justify-between">
              <div class="text-group_3 flex-col justify-between">
                <span class="text_16">作业质量标准</span>
                <span class="text_17"
                  >根据管理的实际要求，自行拟定可量化的作业质量考核标准</span
                >
              </div>
              <div class="text-group_4 flex-col justify-between">
                <span class="text_18">移动考核管理</span>
                <span class="text_19"
                  >对于巡检人员发现的现场卫情，通过拍照或视频的方式留档，便于支撑考核依据</span
                >
              </div>
              <div class="text-group_5 flex-col justify-between">
                <span class="text_20">人员/机构考核汇总</span>
                <span class="text_21"
                  >通过人员或机构的考核规则，对人、车、机构按月进行考核，并关联人、车、机构的危情，违章，违规，出勤等情况</span
                >
              </div>
            </div>
          </div>
          <img
            class="image_11"
            referrerpolicy="no-referrer"
            src="../assets/12.png"
            :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
          />
        </div>
      </div>

      <div class="block_4 flex-col" id="group_2">
        <div
          class="text-group_6 flex-col justify-between"
          :class="TwoShow ? 'animate__animated animate__zoomIn' : ''"
        >
          <span class="text_22">设施巡检子平台</span>
          <span class="text_23"
            >建立环卫设施巡检制度，通过对设施的生命周期监管，实现环卫设施的有效维护。</span
          >
        </div>
        <div class="box_4 flex-row">
          <img
            class="image_12"
            referrerpolicy="no-referrer"
            src="../assets/16.png"
            :class="TwoShow ? 'animate__animated animate__slideInLeft' : ''"
          />
          <div
            class="flex-row"
            :class="TwoShow ? 'animate__animated animate__slideInRight' : ''"
          >
            <div class="group_3 flex-col justify-between">
              <div class="image-wrapper_12 flex-col">
                <img
                  class="label_4"
                  referrerpolicy="no-referrer"
                  src="../assets/17.png"
                />
              </div>
              <div class="image-wrapper_13 flex-col">
                <img
                  class="label_5"
                  referrerpolicy="no-referrer"
                  src="../assets/18.png"
                />
              </div>
              <div class="image-wrapper_14 flex-col">
                <img
                  class="label_6"
                  referrerpolicy="no-referrer"
                  src="../assets/19.png"
                />
              </div>
            </div>
            <div class="group_4 flex-col">
              <span class="text_24">巡检制度</span>
              <span class="text_25"
                >设定巡检机制，对系统内的设施、作业情况进行现场巡查，对问题进行定位，进行图文上报立案</span
              >
              <div class="text-group_7 flex-col justify-between">
                <span class="text_26">设施生命周期</span>
                <span class="text_27"
                  >各类设施的录入、日常养护、报废启用，形成完整的生命周期闭环管理</span
                >
              </div>
              <div class="text-group_8 flex-col justify-between">
                <span class="text_28">多样化的巡检终端</span>
                <span class="text_29"
                  >可接入无人机、VR等当前热门智能终端，实现巡查的智能化/科技化/便捷化</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block_5 flex-col" id="group_3">
        <div
          class="text-wrapper_4 flex-row"
          :class="ThreeShow ? 'animate__animated animate__zoomIn' : ''"
        >
          <span class="text_30">视频监控中心</span>
        </div>
        <div class="box_5 flex-row">
          <div class="image-wrapper_15 flex-col">
            <img
              class="label_7"
              referrerpolicy="no-referrer"
              src="../assets/22.png"
            />
          </div>
          <div class="text-group_9 flex-col justify-between">
            <span class="text_31">可视化管理</span>
            <span class="text_32">远程监管可视化</span>
          </div>
          <div class="image-wrapper_16 flex-col">
            <img
              class="label_8"
              referrerpolicy="no-referrer"
              src="../assets/23.png"
            />
          </div>
          <div class="text-group_10 flex-col justify-between">
            <span class="text_33">多维度场景监控</span>
            <span class="text_34">全局掌握运维动态</span>
          </div>
          <div class="image-wrapper_17 flex-col">
            <img
              class="label_9"
              referrerpolicy="no-referrer"
              src="../assets/24.png"
            />
          </div>
          <div class="text-wrapper_5 flex-col justify-between">
            <span class="text_35">视频的多面呈现</span>
            <span class="text_36">多屏展示，提供作业高清视频监控</span>
          </div>
          <div class="image-wrapper_18 flex-col">
            <img
              class="label_10"
              referrerpolicy="no-referrer"
              src="../assets/25.png"
            />
          </div>
          <div class="text-group_11 flex-col justify-between">
            <span class="text_37">AI识别</span>
            <span class="text_38">通过AI技术识别环境违规行为</span>
          </div>
        </div>
      </div>
      <div class="block_6 flex-col" id="group_4">
        <div :class="FourShow ? 'animate__animated animate__zoomIn' : ''">
          <div class="text_39">大数据分析</div>
          <div class="text_40">
            针对环卫运营中产生的各类数据源，平台进行自动分析和汇总，为管理者提供所需的管理支撑决策依据，同时可通过监控大屏实现环卫大数据的走势及研判
          </div>
        </div>
        <div class="group_5 flex-col">
          <div class="box_6 flex-row justify-between">
            <div class="flex-col align-center">
              <div class="image-wrapper_19 flex-col">
                <img
                  class="label_11"
                  referrerpolicy="no-referrer"
                  src="../assets/26.png"
                />
              </div>
              <div class="text_41">专题运营趋势分析</div>
              <div class="text_42">
                成本走势分析，问题趋势分析，油量使用分析、人均作业量分析、热力图分析
              </div>
            </div>
            <div class="flex-col align-center">
              <div class="image-wrapper_19 flex-col">
                <img
                  class="label_12"
                  referrerpolicy="no-referrer"
                  src="../assets/27.png"
                />
              </div>
              <div class="text_41">多维度的数据分析</div>
              <div class="text_42">
                针对工作中产生的各类数据，进行作业状态分析、车辆行驶报表分析、公厕运维分析、事件统计报表等
              </div>
            </div>
            <div class="flex-col align-center">
              <div class="image-wrapper_19 flex-col">
                <img
                  class="label_13"
                  referrerpolicy="no-referrer"
                  src="../assets/28.png"
                />
              </div>
              <div class="text_41">可视化大屏</div>
              <div class="text_42">
                包含数十种酷炫图标，支持文本图片、视频等，可以任意布局完美适配
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="section_32 flex-row">
      <div class="box_47 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="../assets/35.png"
        />
        <span class="text_104">河南品讯网络科技有限公司</span>
        <span class="text_105">0379-61118888</span>
        <span class="paragraph_25"
          >地址：河南省洛阳市老城区青年创业大厦24层</span
        >
      </div>
      <div class="box_48 flex-col"></div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106">产品体系</span>
        <span
          class="text_107"
          @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
          >远程踏勘管理系统
        </span>
        <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
          >友推云·电商SaaS平台</span
        >
        <span class="text_107" @click="goOther('https://fastrms.hnpinxun.cn/')"
          >视频资源管理AI工具</span
        >
        <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
          >亥时学习平台</span
        >
        <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
          >文明积分银行</span
        >
        <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
          >邻里中心智慧平台</span
        >
      </div>

      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
          >工厂生产管理系统</span
        >
        <span class="text_107" @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
          >酒店系统</span
        >
        <span class="text_107" @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
          >党员双报到</span
        >
        <span class="text_107" @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
          >货车道路救援系统</span
        >
        <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
          >招商宣传</span
        >
        <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
          >智慧环卫综合平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
          >家政行业公共服务平台</span
        >
      </div>
      <div class="box_49 flex-col"></div>
      <div class="box_50 flex-col justify-between">
        <img
          class="image_43"
          referrerpolicy="no-referrer"
          src="../assets/33.png"
        />
        <span class="text_120">微信小程序</span>
      </div>
      <div class="box_51 flex-col justify-between">
        <img
          class="image_44"
          referrerpolicy="no-referrer"
          src="../assets/34.png"
        />
        <span class="text_121">微信公众号</span>
      </div>
    </div>
    <div class="text-wrapper_19 flex-col">
      <span class="text_44"
        >Copyright&nbsp;©&nbsp;2024&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
      >
    </div>

    <!--  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          image: require("../assets/11.png"),
          name: "环卫一张图",
          bigImage: require("../assets/11.png"),
        },
        {
          image: require("../assets/30.png"),
          name: "车辆管理",
          bigImage: require("../assets/30.png"),
        },
        {
          image: require("../assets/31.png"),
          name: "人员管理",
          bigImage: require("../assets/31.png"),
        },
        {
          image: require("../assets/36.png"),
          name: "航宇环卫所",
          bigImage: require("../assets/36.png"),
        },
        {
          image: require("../assets/37.png"),
          name: "巡检人员",
          bigImage: require("../assets/37.png"),
        },
        {
          image: require("../assets/32.png"),
          name: "厕所管理",
          bigImage: require("../assets/32.png"),
        },
      ],
      constants: {},
      OneShow: false,
      TwoShow: false,
      FourShow: false,
      ThreeShow: false,
      listIndex: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  BeforeUnloadEvent() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    listClick(e) {
      this.listIndex = e;
    },
    freeClick() {
      window.open("http://www.hnpinxun.cn/#/", "_blank");
    },
    serveClick(e) {
      this.serveIndex = e;
    },
    handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      var group1Top = document.getElementById("group_1").offsetTop;
      const isGroupVisible =
        scrollPosition >= group1Top - window.innerHeight &&
        scrollPosition < group1Top;

      if (isGroupVisible && !this.OneShow) {
        this.OneShow = true;
      } else if (scrollPosition === 0) {
        this.OneShow = false;
      }
      //
      var group2Top = document.getElementById("group_2").offsetTop;
      const isGroupVisible2 =
        scrollPosition >= group2Top - window.innerHeight &&
        scrollPosition < group2Top;

      if (isGroupVisible2 && !this.TwoShow) {
        this.TwoShow = true;
      } else if (scrollPosition === 0) {
        this.TwoShow = false;
      }

      //
      var group3Top = document.getElementById("group_3").offsetTop;
      const isGroupVisible3 =
        scrollPosition >= group3Top - window.innerHeight &&
        scrollPosition < group3Top;

      if (isGroupVisible3 && !this.ThreeShow) {
        this.ThreeShow = true;
      } else if (scrollPosition === 0) {
        this.ThreeShow = false;
      }

      //
      var group4Top = document.getElementById("group_4").offsetTop;
      const isGroupVisible4 =
        scrollPosition >= group4Top - window.innerHeight &&
        scrollPosition < group4Top;

      if (isGroupVisible4 && !this.FourShow) {
        this.FourShow = true;
      } else if (scrollPosition === 0) {
        this.FourShow = false;
      }
    },
    goOther(e) {
      window.open(e, "_blank");
    },
  },
};
</script>

<style scoped>
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
}

.box_1 {
  width: 100vw;
  height: 41.67vw;
  background: url("/src/assets/29.png") 100% no-repeat;
  background-size: 100% 100%;
}

.text_1 {
  width: 20.58vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.66vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin: 10.41vw 0 0 18.75vw;
}

.text_2 {
  width: 25vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 1.56vw 0 0 18.75vw;
}

.paragraph_1 {
  width: 24.38vw;
  height: 6.78vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  margin: 1.56vw 0 0 18.75vw;
}

.box_2 {
  box-shadow: 0px 4px 6px 0px rgba(55, 178, 120, 0.35);
  height: 2.61vw;
  width: 8.34vw;
  margin: 1.97vw 0 11.97vw 18.75vw;
}

.text-wrapper_1 {
  background-color: rgba(58, 190, 128, 1);
  border-radius: 5px;
  height: 2.61vw;
  width: 8.34vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 2.61vw;
  cursor: pointer;
}
.text-wrapper_1:hover {
  transform: scale(1.1);
}

.box_3 {
  width: 100vw;
}

.text-group_1 {
  width: 59.07vw;
  height: 7.09vw;
  margin: 4.16vw 0 0 20.46vw;
}
.align-center:hover {
  transform: translateY(-10px);
}

.text_4 {
  width: 12.5vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(26, 156, 119, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-left: 23.29vw;
}

.paragraph_2 {
  width: 59.07vw;
  height: 2.4vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  margin-top: 1.57vw;
}

.block_1 {
  width: 54.17vw;
  margin: 3.12vw 0 0 22.91vw;
}

.image-wrapper_1 {
  height: 7.3vw;
  width: 7.3vw;
}

.image_1 {
  width: 4.17vw;
  height: 4.17vw;
  margin: 1.56vw 0 0 1.56vw;
}

.image-wrapper_2 {
  background-color: rgba(26, 156, 119, 1);
  border-radius: 50%;
  height: 7.3vw;
  margin-left: 8.34vw;
  width: 7.3vw;
}

.image_2 {
  width: 4.17vw;
  height: 4.17vw;
  margin: 1.56vw 0 0 1.56vw;
}

.text_5 {
  margin-top: 1.56vw;
  width: 3.75vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
}

.text_13 {
  width: 12.5vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(26, 156, 119, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 5.21vw;
  margin: 4.27vw 0 0 43.75vw;
}

.list_1 {
  width: 59.38vw;
  height: 7.19vw;
  justify-content: space-between;
  margin: 3.12vw 0 0 20.31vw;
}

.image-text_1 {
  width: 5.21vw;
  height: 7.19vw;
  margin-right: 1.57vw;
}

.image-text_1:hover {
  .image_9 {
    border: 0.1875vw solid #1a9c77;
    transform: scale(1.1);
  }
  .text-group_2 {
    color: #1a9c77;
    font-weight: bold;
  }
}

.image_9 {
  width: 5.21vw;
  height: 5.21vw;
  border-radius: 0.6667vw;
  border: 0.1875vw solid #dedede;
  object-fit: fill;
}
.image_9_1 {
  width: 5.21vw;
  height: 5.21vw;
  border-radius: 0.6667vw;
  border: 0.1875vw solid #1a9c77;
  transform: scale(1.1);
  object-fit: fill;
}

.text-group_2 {
  width: 4.69vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin: 1.04vw 0 0 0.26vw;
}
.text-group_2_1 {
  width: 4.69vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: #1a9c77;
  font-weight: bold;
  font-size: 0.93vw;
  font-family: MiSans-Regular;
  text-align: center;
  white-space: nowrap;
  margin: 1.04vw 0 0 0.26vw;
}

.image_10 {
  width: 62.5vw;
  height: 35.16vw;
  margin: 2.18vw 0 0 18.75vw;
}

.text_14 {
  width: 8.75vw;
  height: 1.93vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.45vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin: 4.16vw 0 0 45.46vw;
}

.text_15 {
  width: 50.42vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: center;
  line-height: 1.1vw;
  margin: 1.19vw 0 0 25.1vw;
}

.block_3 {
  width: 62.5vw;
  height: 27.09vw;
  margin: 2.03vw 0 0 18.59vw;
}

.group_1 {
  width: 3.03vw;
  height: 16.46vw;
  margin-top: 5.16vw;
}

.image-wrapper_9 {
  border-radius: 28px;
  height: 3.03vw;
  border: 1px solid rgba(145, 144, 144, 0.23);
  width: 3.03vw;
}

.label_1 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.image-wrapper_10 {
  border-radius: 28px;
  height: 3.03vw;
  border: 1px solid rgba(145, 144, 144, 0.23);
  margin-top: 3.49vw;
  width: 3.03vw;
}

.label_2 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.image-wrapper_11 {
  border-radius: 28px;
  height: 3.03vw;
  border: 1px solid rgba(145, 144, 144, 0.23);
  margin-top: 3.91vw;
  width: 3.03vw;
}

.label_3 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.group_2 {
  width: 22.45vw;
  height: 16.57vw;
  margin: 5.31vw 0 0 0.52vw;
}

.text-group_3 {
  width: 18.96vw;
  height: 2.66vw;
}

.text_16 {
  width: 5vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.83vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

.text_17 {
  width: 18.96vw;
  height: 0.99vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.73vw;
  margin-top: 0.58vw;
}

.text-group_4 {
  width: 22.3vw;
  height: 3.65vw;
  margin-top: 3.34vw;
}

.text_18 {
  width: 5vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.83vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

.text_19 {
  width: 22.3vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  margin-top: 0.58vw;
}

.text-group_5 {
  width: 22.45vw;
  height: 3.7vw;
  margin-top: 3.23vw;
}

.text_20 {
  width: 7.04vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.83vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

.text_21 {
  width: 22.45vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: MiSans-Normal;
  font-weight: normal;
  text-align: left;
  margin-top: 0.63vw;
}

.image_11 {
  width: 33.34vw;
  height: 27.09vw;
  margin-left: 3.18vw;
}

.block_4 {
  background-color: rgba(246, 248, 251, 1);
  width: 100vw;
  height: 36.46vw;
  margin-top: 4.17vw;
}

.text-group_6 {
  width: 26.36vw;
  height: 4.17vw;
  margin: 3.12vw 0 0 36.87vw;
}

.text_22 {
  width: 10.27vw;
  height: 1.93vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.45vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  margin-left: 8.03vw;
}

.text_23 {
  width: 26.36vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin-top: 1.15vw;
}

.box_4 {
  width: 62.66vw;
  height: 27.09vw;
  margin: 0.52vw 0 1.56vw 18.69vw;
}

.image_12 {
  width: 33.34vw;
  height: 27.09vw;
}

.group_3 {
  width: 3.03vw;
  height: 16.83vw;
  margin: 4.11vw 0 0 3.22vw;
}

.image-wrapper_12 {
  border-radius: 28px;
  height: 3.03vw;
  border: 1px solid rgba(145, 144, 144, 0.23);
  width: 3.03vw;
}

.label_4 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.46vw;
}

.image-wrapper_13 {
  border-radius: 28px;
  height: 3.03vw;
  border: 1px solid rgba(145, 144, 144, 0.23);
  margin-top: 3.86vw;
  width: 3.03vw;
}

.label_5 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.46vw;
}

.image-wrapper_14 {
  border-radius: 28px;
  height: 3.03vw;
  border: 1px solid rgba(145, 144, 144, 0.23);
  margin-top: 3.91vw;
  width: 3.03vw;
}

.label_6 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.46vw;
}

.group_4 {
  width: 22.61vw;
  height: 17.45vw;
  margin: 3.75vw 0 0 0.46vw;
}

.text_24 {
  width: 3.39vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.83vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}

.text_25 {
  width: 22.45vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  margin-top: 0.58vw;
}

.text-group_7 {
  width: 22.35vw;
  height: 3.7vw;
  margin-top: 3.23vw;
}

.text_26 {
  width: 5.06vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.83vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}

.text_27 {
  width: 22.35vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  margin-top: 0.63vw;
}

.text-group_8 {
  width: 22.61vw;
  height: 3.65vw;
  margin-top: 3.23vw;
}

.text_28 {
  width: 6.72vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.83vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}

.text_29 {
  width: 22.61vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: MiSans-Regular;
  font-weight: normal;
  text-align: left;
  margin-top: 0.58vw;
}

.block_5 {
  height: 37.61vw;
  width: 100vw;
  background: url("/src/assets/20.png") no-repeat;
  background-size: 100% 100%;
}

.text-wrapper_4 {
  width: 8.81vw;
  height: 1.78vw;
  margin: 1.56vw 0 0 45.62vw;
}

.text_30 {
  width: 8.81vw;
  height: 1.78vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.45vw;
  font-family: Inter-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.box_5 {
  width: 61.93vw;
  height: 3.03vw;
  margin: 3.38vw 0 27.86vw 18.69vw;
}

.image-wrapper_15 {
  border-radius: 29px;
  height: 3.03vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  width: 3.08vw;
}

.label_7 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.text-group_9 {
  width: 6.1vw;
  height: 2.97vw;
  margin-left: 0.47vw;
}

.text_31 {
  width: 5vw;
  height: 1.41vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_32 {
  width: 6.1vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.73vw;
  margin-top: 0.32vw;
}

.image-wrapper_16 {
  border-radius: 29px;
  height: 3.03vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  margin-left: 6.05vw;
  width: 3.08vw;
}

.label_8 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.text-group_10 {
  width: 6.93vw;
  height: 2.97vw;
  margin-left: 0.47vw;
}

.text_33 {
  width: 6.93vw;
  height: 1.41vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_34 {
  width: 6.93vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.73vw;
  margin-top: 0.32vw;
}

.image-wrapper_17 {
  border-radius: 29px;
  height: 3.03vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  margin-left: 5.16vw;
  width: 3.08vw;
}

.label_9 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.text-wrapper_5 {
  width: 12.5vw;
  height: 2.97vw;
  margin-left: 0.53vw;
}

.text_35 {
  width: 6.93vw;
  height: 1.41vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_36 {
  width: 12.5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.73vw;
  margin-top: 0.32vw;
}

.image-wrapper_18 {
  border-radius: 29px;
  height: 3.03vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  margin-left: -0.41vw;
  width: 3.08vw;
}

.label_10 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.52vw 0 0 0.52vw;
}

.text-group_11 {
  width: 11.36vw;
  height: 2.97vw;
  margin-left: 0.53vw;
}

.text_37 {
  width: 3.18vw;
  height: 1.41vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_38 {
  width: 11.36vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.73vw;
  margin-top: 0.32vw;
}

.block_6 {
  width: 100vw;
  height: 40.5vw;
  background: url("/src/assets/21.png") no-repeat;
  background-size: 100% 100%;
}

.text_39 {
  width: 7.35vw;
  height: 1.78vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.45vw;
  font-family: Inter-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  margin: 1.56vw 0 0 46.35vw;
}

.text_40 {
  width: 49.12vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.62vw;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin: 1.3vw 0 0 28.69vw;
}

.group_5 {
  margin: 25.26vw 0 0.1vw 0;
}

.box_6 {
  width: 57.53vw;
  height: 3.86vw;
  margin: 0.3vw 0 0 21.39vw;
}

.image-wrapper_19 {
  border-radius: 37px;
  height: 3.86vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  width: 3.86vw;
  margin-bottom: 0.625vw;
}

.label_11 {
  width: 2.09vw;
  height: 1.98vw;
  margin: 0.93vw 0 0 0.88vw;
}

.image-wrapper_20 {
  border-radius: 37px;
  height: 3.86vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  margin-left: 16.83vw;
  width: 3.86vw;
}

.label_12 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.93vw 0 0 0.93vw;
}

.image-wrapper_21 {
  border-radius: 37px;
  height: 3.86vw;
  border: 1px solid rgba(169, 169, 169, 0.33);
  margin-left: 17.14vw;
  width: 3.86vw;
}

.label_13 {
  width: 1.98vw;
  height: 1.98vw;
  margin: 0.93vw 0 0 0.93vw;
}

.text_41 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.text_42 {
  width: 16.05vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.62vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  margin-top: 0.63vw;
}

/*  */
/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #0b74ef;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}
.text-wrapper_19 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  margin-top: -0.05vw;
}

.text_44 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
</style>
